import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { ExternalSystemInstanceType } from '../../types/external-system-instance/external-system-instance.type';
import { ExternalSystemProvider } from '../../utils/constants';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';

export const getExternalSystems = createAsyncThunk<
  Array<ExternalSystemInstanceType>,
  {
    type?: 'TELCO' | 'PBX';
    organizationID?: number;
    telcoID?: number;
    isGlobal: boolean;
  },
  { state: RootState }
>('externalSystems/getExternalSystems', async ({ type, organizationID, isGlobal, telcoID }, thunkAPI) => {
  try {
    let url: string;
    if (isGlobal) {
      url = `${process.env.REACT_APP_API_URL}/external-systems/global?${type ? `type=${type}` : ''}&${
        organizationID ? `org_id=${organizationID}` : ''
      }${telcoID ? `&telcoID=${telcoID}` : ''}`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/external-systems?${type ? `type=${type}` : ''}&${
        organizationID ? `org_id=${organizationID}` : ''
      }${telcoID ? `&telcoID=${telcoID}` : ''}`;
    }
    const response = await axios.get<Array<ExternalSystemInstanceType>>(url);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting external systems!');
  }
});

export const getExternalSystemInstanceDetails = createAsyncThunk<
  ExternalSystemInstanceType,
  { id: number },
  { state: RootState }
>('externalSystems/getExternalSystemInstanceDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ExternalSystemInstanceType>(
      `${process.env.REACT_APP_API_URL}/external-systems/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting external system!');
  }
});

export const postExternalSystemDetails = createAsyncThunk<
  { success: boolean },
  { type: ExternalSystemProvider; dto: unknown },
  { state: RootState }
>('externalSystems/postExternalSystemDetails', async ({ type, dto }, thunkAPI) => {
  try {
    let url = '';
    if (type === ExternalSystemProvider.MEGASWITCH_PBX) {
      url = `${process.env.REACT_APP_API_URL}/external-systems/megaswitch`;
    } else if (type === ExternalSystemProvider.NETSAPIENS_PBX) {
      url = `${process.env.REACT_APP_API_URL}/external-systems/netsapiens`;
    } else if (type === ExternalSystemProvider.SKYSWITCH_PBX) {
      url = `${process.env.REACT_APP_API_URL}/external-systems/skyswitch-pbx`;
    } else if (type === ExternalSystemProvider.QUESTBLUE) {
      url = `${process.env.REACT_APP_API_URL}/external-systems/questblue`;
    } else if (type === ExternalSystemProvider.SKYSWITCH_TELCO) {
      url = `${process.env.REACT_APP_API_URL}/external-systems/skyswitch-telco`;
    } else if (type === ExternalSystemProvider.ROUTE_TRUST_TELCO) {
      url = `${process.env.REACT_APP_API_URL}/external-systems/routetrust`;
    } else if (type === ExternalSystemProvider.SINCH) {
      url = `${process.env.REACT_APP_API_URL}/external-systems/sinch`;
    }

    const response = await axios.post<ExternalSystemInstanceType>(url, dto);

    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/external-systems/${response.data.id}/test`);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      thunkAPI.dispatch(getExternalSystems({ organizationID: dto.organization.id, isGlobal: false }));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'External System Credentials are not valid!');
    }
    return { success: true };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating external systems!');
  }
});

export const deleteExternalSystemDetails = createAsyncThunk<
  { success: boolean },
  { id: number; organizationID: number },
  { state: RootState }
>('externalSystems/deleteExternalSystemDetails', async ({ id, organizationID }, thunkAPI) => {
  try {
    const response = await axios.delete<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/external-systems/${id}`);
    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(getExternalSystems({ organizationID, isGlobal: false }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting external systems!');
  }
});

export const patchExternalSystemDetails = createAsyncThunk<
  ExternalSystemInstanceType,
  {
    id: number;
    organizationID: number;
    data: Partial<ExternalSystemInstanceType> & {
      enabledByDefault: boolean;
      inheritanceAvailable?: boolean;
      registerCDRs?: boolean;
    };
  },
  { state: RootState }
>('externalSystems/patchExternalSystemDetails', async ({ data, organizationID, id }, thunkAPI) => {
  try {
    if (data.registerCDRs) {
      await axios.get(`${process.env.REACT_APP_API_URL}/netsapiens/${id}/createCDRCallSubscription`).catch(error => {
        throw new Error('Error during creating CDR call subscription!');
      });
    }
    const response = await axios.patch<ExternalSystemInstanceType>(
      `${process.env.REACT_APP_API_URL}/external-systems/${id}`,
      {
        ...data,
      }
    );

    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(getExternalSystems({ organizationID: organizationID, isGlobal: false }));
    thunkAPI.dispatch(getExternalSystems({ organizationID: organizationID, isGlobal: true }));
    thunkAPI.dispatch(getExternalSystemsProviders({ organizationID: organizationID }));
    thunkAPI.dispatch(getExternalSystemsPBX({ organizationID: organizationID }));
    thunkAPI.dispatch(getExternalSystemMapperList({ organizationID: organizationID }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || error?.message || 'Error during updating external systems!'
    );
  }
});

export const patchGlobalExternalSystemDetails = createAsyncThunk<
  ExternalSystemInstanceType,
  { id: number; data: { enabled: boolean; organizationID: number } },
  { state: RootState }
>('externalSystems/patchGlobalExternalSystemDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.post<ExternalSystemInstanceType>(
      `${process.env.REACT_APP_API_URL}/external-systems/${id}/${data.organizationID}?enable=${data.enabled}`,
      {}
    );

    thunkAPI.dispatch(changeModalState(false));
    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(getExternalSystems({ organizationID: data.organizationID, isGlobal: false }));
    thunkAPI.dispatch(getExternalSystems({ organizationID: data.organizationID, isGlobal: true }));
    thunkAPI.dispatch(getExternalSystemsProviders({ organizationID: data.organizationID }));
    thunkAPI.dispatch(getExternalSystemsPBX({ organizationID: data.organizationID }));
    thunkAPI.dispatch(getExternalSystemMapperList({ organizationID: data.organizationID }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating global external systems!');
  }
});

export const getExternalSystemMapperList = createAsyncThunk<
  Array<ExternalSystemInstanceType>,
  { organizationID: number },
  { state: RootState }
>('externalSystems/getExternalSystemMapperList', async ({ organizationID }, thunkAPI) => {
  try {
    const response = await axios.get<Array<ExternalSystemInstanceType>>(
      `${process.env.REACT_APP_API_URL}/external-systems/routing-config/?org_id=${organizationID}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response.data.message || 'Error during getting external systems mapper list!'
    );
  }
});

export const getExternalSystemsProviders = createAsyncThunk<
  Array<ExternalSystemInstanceType>,
  { organizationID?: number },
  { state: RootState }
>('externalSystems/getExternalSystemsProviders', async ({ organizationID }, thunkAPI) => {
  try {
    const response = await axios.get<Array<ExternalSystemInstanceType>>(
      `${process.env.REACT_APP_API_URL}/external-systems?type=TELCO&${organizationID ? `org_id=${organizationID}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting external provider systems!');
  }
});

export const getExternalSystemsPBX = createAsyncThunk<
  Array<ExternalSystemInstanceType>,
  { organizationID?: number },
  { state: RootState }
>('externalSystems/getExternalSystemsPBX', async ({ organizationID }, thunkAPI) => {
  try {
    const response = await axios.get<Array<ExternalSystemInstanceType>>(
      `${process.env.REACT_APP_API_URL}/external-systems?type=PBX&${organizationID ? `org_id=${organizationID}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting external PBX systems!');
  }
});

export const postRouteConfig = createAsyncThunk<
  { success: boolean },
  { orgId: number; systemId: number; providerId: number; config: string; e911: boolean },
  { state: RootState }
>('externalSystems/postRouteConfig', async ({ orgId, config, systemId, providerId, e911 }, thunkAPI) => {
  try {
    const response = await axios.post<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/external-systems/routing-config?${orgId ? `org_id=${orgId}` : ''}`,
      {
        system: { id: systemId },
        provider: { id: providerId },
        e911_pbx: e911,
        config: config,
      }
    );
    thunkAPI.dispatch(getExternalSystemMapperList({ organizationID: orgId }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting external PBX systems!');
  }
});

export const deleteRouteConfig = createAsyncThunk<
  { success: boolean },
  { orgId: number; configId: number },
  { state: RootState }
>('externalSystems/deleteRouteConfig', async ({ orgId, configId }, thunkAPI) => {
  try {
    const response = await axios.delete<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/external-systems/routing-config/${configId}`
    );
    thunkAPI.dispatch(getExternalSystemMapperList({ organizationID: orgId }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response.data.message || 'Error during deleting external systems routing config!'
    );
  }
});

export const patchRouteConfig = createAsyncThunk<
  { success: boolean },
  { orgId: number; configId: number; config: string; e911: boolean },
  { state: RootState }
>('externalSystems/patchRouteConfig', async ({ orgId, config, configId, e911 }, thunkAPI) => {
  try {
    const response = await axios.patch<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/external-systems/routing-config/${configId}`,
      {
        config: config,
        e911_pbx: e911,
      }
    );
    thunkAPI.dispatch(getExternalSystemMapperList({ organizationID: orgId }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response.data.message || 'Error during updating external systems routing config!'
    );
  }
});

export const getPBXDomainsList = createAsyncThunk<
  Array<{
    description: string;
    domain: string;
  }>,
  { externalSystemId: number },
  { state: RootState }
>('externalSystems/getPBXDomainsList', async ({ externalSystemId }, thunkAPI) => {
  try {
    const response = await axios.get<
      Array<{
        description: string;
        domain: string;
      }>
    >(`${process.env.REACT_APP_API_URL}/external-systems/getAvailableDomains?id=${externalSystemId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting domains list!');
  }
});

export const postPBXDomains = createAsyncThunk<
  { success: boolean },
  {
    externalSystemInstance: { id: number };
    organization: { id: number };
    domains: string[];
  },
  { state: RootState }
>('externalSystems/postPBXDomains', async (data, thunkAPI) => {
  try {
    const response = await axios.post<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/external-systems/importDomains`,
      data
    );
    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(changeModalState(false));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during importing domains!');
  }
});
