// utils CONSTANTS
export const SET_ROUTE_PARAMS = 'SET_ROUTE_PARAMS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';

// porting CONSTANTS
export const POST_PORTING_DETAILS_STATUS = 'POST_PORTING_DETAILS_STATUS';
export const SET_PORTING_LIST = 'SET_PORTING_LIST';
export const FETCH_PORTING_LIST_STATUS = 'FETCH_PORTING_LIST_STATUS';
export const SET_PORTING_DETAILS = 'SET_PORTING_DETAILS';
export const FETCH_PORTING_DETAILS_STATUS = 'FETCH_PORTING_DETAILS_STATUS';
export const DELETE_PORTING_DETAILS_STATUS = 'DELETE_PORTING_DETAILS_STATUS';
export const CANCEL_PORTING_DETAILS_STATUS = 'CANCEL_PORTING_DETAILS_STATUS';
export const PATCH_PORTING_DETAILS_STATUS = 'PATCH_PORTING_DETAILS_STATUS';

// Orders CONSTANTS
export const SET_ORDERS_LIST = 'SET_ORDERS_LIST';
export const FETCH_ORDERS_LIST_STATUS = 'FETCH_ORDERS_LIST_STATUS';
export const SET_ORDERS_DETAILS = 'SET_ORDERS_DETAILS';
export const FETCH_ORDERS_DETAILS_STATUS = 'FETCH_ORDERS_DETAILS_STATUS';
export const PATCH_ORDERS_DETAILS_STATUS = 'PATCH_ORDERS_DETAILS_STATUS';
export const POST_SIM_ACTIVATION_EMAIL_STATUS = 'POST_SIM_ACTIVATION_EMAIL_STATUS';
export const POST_TRACKING_INFO_EMAIL_STATUS = 'POST_TRACKING_INFO_EMAIL_STATUS';
export const POST_ORDERS_DETAILS_STATUS = 'POST_ORDERS_DETAILS_STATUS';
export const DELETE_ORDERS_DETAILS_STATUS = 'DELETE_ORDERS_DETAILS_STATUS';

// authentication CONSTANTS
export const SET_USER_DATA = 'SET_USER_DATA';
export const FETCH_USER_DATA_STATUS = 'FETCH_USER_DATA_STATUS';
export const SET_CURRENT_USER_ACCESS_LIST = 'SET_CURRENT_USER_ACCESS_LIST';
export const FETCH_USER_CURRENT_ACCESS_LIST_STATUS = 'FETCH_USER_CURRENT_ACCESS_LIST_STATUS';
export const TOKEN_CHECKED = 'TOKEN_CHECKED';
export const FETCH_LOGIN_STATUS = 'FETCH_LOGIN_STATUS';
export const FETCH_REDIRECT_STATUS = 'FETCH_REDIRECT_STATUS';

// public CONSTANTS
export const SET_PUBLIC_EQUIPMENT_GROUPS_DETAILS = 'SET_PUBLIC_EQUIPMENT_GROUPS_DETAILS';
export const FETCH_PUBLIC_EQUIPMENT_GROUPS_DETAILS_STATUS = 'FETCH_PUBLIC_EQUIPMENT_GROUPS_DETAILS_STATUS';
export const SET_PUBLIC_LOCATION_DETAILS = 'SET_PUBLIC_LOCATION_DETAILS';
export const FETCH_PUBLIC_LOCATION_DETAILS_STATUS = 'FETTCH_PUBLIC_LOCATION_DETAILS_STATUS';

// SHARED CONSTANTS
export const SET_JOT_FORM_URL = 'SET_JOT_FORM_URL';
export const SET_JOT_FORM_URL_FETCH_STATUS = 'SET_JOT_FORM_URL_FETCH_STATUS';
export const SET_FULL_ORGANIZATION_LIST = 'SET_FULL_ORGANIZATION_LIST';
export const SET_FULL_ORGANIZATION_LIST_FETCH_STATUS = 'SET_FULL_ORGANIZATION_LIST_FETCH_STATUS';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_SELECTED_USER_ACCESS = 'SET_SELECTED_USER_ACCESS';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
export const SET_INCLUDE_SUBORGS = 'SET_INCLUDE_SUBORGS';
export const FETCH_CHECK_DOMAIN_STATUS = 'FETCH_CHECK_DOMAIN_STATUS';
export const SET_DOMAIN_DATA = 'SET_DOMAIN_DATA';
export const SET_FULL_LOCATION_LIST = 'SET_FULL_LOCATION_LIST';
export const SET_FULL_LOCATION_LIST_FETCH_STATUS = 'SET_FULL_LOCATION_LIST_FETCH_STATUS';
export const SET_FULL_ATA_TYPE_LIST = 'SET_FULL_ATA_TYPE_LIST';
export const SET_FULL_ATA_TYPE_LIST_FETCH_STATUS = 'SET_FULL_ATA_TYPE_LIST_FETCH_STATUS';
export const SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST = 'SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST';
export const SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS =
  'SET_FULL_ATA_ORGANIZATION_TEMPLATE_LIST_FETCH_STATUS';
export const SET_FULL_DID_LIST = 'SET_FULL_DID_LIST';
export const SET_FULL_DID_LIST_FETCH_STATUS = 'SET_FULL_DID_LIST_FETCH_STATUS';
export const SET_FULL_ATA_ORG_TEMPLATE_LIST = 'SET_FULL_ATA_ORG_TEMPLATE_LIST';
export const SET_FULL_ATA_ORG_TEMPLATE_LIST_FETCH_STATUS = 'SET_FULL_ATA_ORG_TEMPLATE_LIST_FETCH_STATUS';
export const SET_FULL_ATA_BASE_TEMPLATE_LIST = 'SET_FULL_ATA_BASE_TEMPLATE_LIST';
export const SET_FULL_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS = 'SET_FULL_ATA_BASE_TEMPLATE_LIST_FETCH_STATUS';
export const SET_FULL_PBX_LIST = 'SET_FULL_PBX_LIST';
export const SET_FULL_PBX_LIST_FETCH_STATUS = 'SET_FULL_PBX_LIST_FETCH_STATUS';
export const SET_FULL_ROUTER_LIST = 'SET_FULL_ROUTER_LIST';
export const SET_FULL_ROUTER_LIST_FETCH_STATUS = 'SET_FULL_ROUTER_LIST_FETCH_STATUS';
export const SET_FULL_SERVICE_TYPE_LIST = 'SET_FULL_SERVICE_TYPE_LIST';
export const SET_FULL_SERVICE_TYPE_CATEGORY_LIST = 'SET_FULL_SERVICE_TYPE_CATEGORY_LIST';
export const SET_FULL_SERVICE_TYPE_CATEGORY_LIST_FETCH_STATUS = 'SET_FULL_SERVICE_TYPE_CATEGORY_LIST_FETCH_STATUS';
export const SET_FULL_ROUTER_PRODUCT_NAME_LIST = 'SET_FULL_ROUTER_PRODUCT_NAME_LIST';
export const SET_FULL_ROUTER_PRODUCT_NAME_LIST_FETCH_STATUS = 'SET_FULL_ROUTER_PRODUCT_NAME_LIST_FETCH_STATUS';
export const SET_FULL_SIM_CARRIER_LIST = 'SET_FULL_SIM_CARRIER_LIST';
export const SET_FULL_SIM_CARRIER_LIST_FETCH_STATUS = 'SET_FULL_SIM_CARRIER_LIST_FETCH_STATUS';
export const SET_FULL_SERVICE_TYPE_LIST_FETCH_STATUS = 'SET_FULL_SERVICE_TYPE_LIST_FETCH_STATUS';
export const SET_FULL_SIM_LIST = 'SET_FULL_SIM_LIST';
export const SET_FULL_VERIFIED_EMAILS_LIST = 'SET_FULL_VERIFIED_EMAILS_LIST';
export const SET_FULL_VERIFIED_NUMBERS_LIST = 'SET_FULL_VERIFIED_NUMBERS_LIST';
export const SET_FULL_VERIFIED_EMAILS_FETCH_STATUS = 'SET_FULL_VERIFIED_EMAILS_FETCH_STATUS';
export const SET_FULL_VERIFIED_NUMBERS_FETCH_STATUS = 'SET_FULL_VERIFIED_NUMBERS_FETCH_STATUS';
export const SET_FULL_SIM_LIST_FETCH_STATUS = 'SET_FULL_SIM_LIST_FETCH_STATUS';
export const SET_FULL_DATA_PLAN_TYPE_LIST = 'SET_FULL_DATA_PLAN_TYPE_LIST';
export const SET_FULL_DATA_PLAN_TYPE_LIST_FETCH_STATUS = 'SET_FULL_DATA_PLAN_TYPE_LIST_FETCH_STATUS';
export const SET_FULL_ISSUES_TRACKER_CODES_LIST = 'SET_FULL_ISSUES_TRACKER_CODES_LIST';
export const SET_FULL_ISSUES_TRACKER_CODES_LIST_FETCH_STATUS = 'SET_FULL_ISSUES_TRACKER_CODES_LIST_FETCH_STATUS';
export const SET_FULL_AUDIT_ENTITY_TYPES = 'SET_FULL_AUDIT_ENTITY_TYPES';
export const SET_FULL_AUDIT_ENTITY_TYPES_FETCH_STATUS = 'SET_FULL_AUDIT_ENTITY_TYPES_FETCH_STATUS';
export const SET_FULL_EXTERNAL_SYSTEM_LIST = 'SET_FULL_EXTERNAL_SYSTEM_LIST';
export const SET_FULL_EXTERNAL_PROVIDER_LIST = 'SET_FULL_EXTERNAL_PROVIDER_LIST';
export const SET_FULL_EXTERNAL_SYSTEM_LIST_FETCH_STATUS = 'SET_FULL_EXTERNAL_SYSTEM_LIST_FETCH_STATUS';

export const SET_FULL_COUNTRIES_LIST = 'SET_FULL_COUNTRIES_LIST';
export const SET_FULL_GROUPED_COUNTRIES_LIST = 'SET_FULL_GROUPED_COUNTRIES_LIST';
export const SET_CACHED_FULL_COUNTRIES_LIST = 'SET_CACHED_FULL_COUNTRIES_LIST';
export const SET_CACHED_FULL_GROUPED_COUNTRIES_LIST = 'SET_CACHED_FULL_GROUPED_COUNTRIES_LIST';
export const SET_FULL_COUNTRIES_LIST_FETCH_STATUS = 'SET_FULL_COUNTRIES_LIST_FETCH_STATUS';

export const SET_FULL_EQUIPMENT_GROUP_LIST = 'SET_FULL_EQUIPMENT_GROUP_LIST';
export const SET_FULL_EQUIPMENT_GROUP_LIST_FETCH_STATUS = 'SET_FULL_EQUIPMENT_GROUP_LIST_FETCH_STATUS';
export const SET_FULL_SERVICE_LIST = 'SET_FULL_SERVICE_LIST';
export const SET_FULL_DIALER_TYPES_LIST = 'SET_FULL_DIALER_TYPES_LIST';
export const SET_FULL_DIALER_TYPES_LIST_FETCH_STATUS = 'SET_FULL_DIALER_TYPES_LIST_FETCH_STATUS';
export const SET_FULL_SERVICE_LIST_FETCH_STATUS = 'SET_FULL_SERVICE_LIST_FETCH_STATUS';
export const SET_FULL_ENCLOSURE_TYPES_LIST = 'SET_FULL_ENCLOSURE_TYPES_LIST';
export const SET_FULL_ENCLOSURE_TYPES_LIST_FETCH_STATUS = 'SET_FULL_ENCLOSURE_TYPES_LIST_FETCH_STATUS';
export const SET_FULL_COUNTRY_CATEGORIES_LIST = 'SET_FULL_COUNTRY_CATEGORIES_LIST';
export const SET_FULL_COUNTRY_CATEGORIES_LIST_FETCH_STATUS = 'SET_FULL_COUNTRY_CATEGORIES_LIST_FETCH_STATUS';
export const SET_FULL_WAREHOUSE_LIST = 'SET_FULL_WAREHOUSE_LIST';
export const SET_FULL_WAREHOUSE_LIST_FETCH_STATUS = 'SET_FULL_WAREHOUSE_LIST_FETCH_STATUS';

export const SET_FULL_ATAS_ACS_PROFILE_LIST = 'SET_FULL_ATAS_ACS_PROFILE_LIST';
export const SET_FULL_ATAS_ACS_PROFILE_LIST_FETCH_STATUS = 'SET_FULL_ATAS_ACS_PROFILE_LIST_FETCH_STATUS';
export const SET_FULL_ASYNC_TASK_TYPES_LIST = 'SET_FULL_ASYNC_TASK_TYPES_LIST';
export const SET_FULL_ASYNC_TASK_TYPES_LIST_FETCH_STATUS = 'SET_FULL_ASYNC_TASK_TYPES_LIST_FETCH_STATUS';
export const SET_FULL_TC_BLOCK_LIST = 'SET_FULL_TC_BLOCK_LIST';
export const SET_FULL_TC_BLOCK_LIST_FETCH_STATUS = 'SET_FULL_TC_BLOCK_LIST_FETCH_STATUS';
export const SET_FULL_LINE_TYPES_LIST = 'SET_FULL_LINE_TYPES_LIST';
export const SET_FULL_LINE_TYPES_LIST_FETCH_STATUS = 'SET_FULL_LINE_TYPES_LIST_FETCH_STATUS';
export const SET_FULL_BUCKETS_LIST = 'SET_FULL_BUCKETS_LIST';
export const SET_FULL_BUCKETS_LIST_FETCH_STATUS = 'SET_FULL_BUCKETS_LIST_FETCH_STATUS';
export const SET_TIMEZONE = 'SET_TIMEZONE';

// locations CONSTANTS
export const SET_LOCATIONS_LIST = 'SET_LOCATIONS_LIST';
export const FETCH_LOCATIONS_LIST_STATUS = 'FETCH_LOCATIONS_LIST_STATUS';
export const SET_LOCATIONS_DETAILS = 'SET_LOCATIONS_DETAILS';
export const FETCH_LOCATIONS_DETAILS_STATUS = 'FETCH_LOCATIONS_DETAILS_STATUS';
export const SET_LOCATIONS_TELCO = 'SET_LOCATIONS_TELCO';
export const FETCH_LOCATIONS_TELCO_STATUS = 'FETCH_LOCATIONS_TELCO_STATUS';
export const DELETE_LOCATIONS_DETAILS_STATUS = 'DELETE_LOCATIONS_DETAILS_STATUS';
export const SET_LOCATIONS_ROUTE_PARAMS = 'SET_LOCATIONS_ROUTE_PARAMS';
export const POST_LOCATIONS_DETAILS_STATUS = 'POST_LOCATIONS_DETAILS_STATUS';
export const PATCH_LOCATIONS_DETAILS_STATUS = 'PATCH_LOCATIONS_DETAILS_STATUS';
export const POST_LOCATION_VALIDATION_STATUS = 'POST_LOCATION_VALIDATION_STATUS';
export const SET_LOCATION_URL_IMAGE = 'SET_LOCATION_URL_IMAGE';
export const FETCH_LOCATION_URL_IMAGE = 'FETCH_LOCATION_URL_IMAGE';
export const FETCH_LOCATION_SERVICES_LIST_STATUS = 'FETCH_LOCATIONS_SERVICES_LIST';
export const SET_LOCATION_SERVICES_LIST = 'SET_LOCATIONS_SERVICES_LIST';
export const FETCH_LOCATION_EQUIPMENT_LIST_STATUS = 'FETCH_LOCATIONS_EQUIPMENT_LIST';
export const SET_LOCATION_EQUIPMENT_LIST = 'SET_LOCATIONS_EQUIPMENT_LIST';

export const FETCH_LOCATION_CONNECTIVITY_DATA_STATUS = 'FETCH_LOCATION_CONNECTIVITY_DATA_STATUS';
export const SET_LOCATION_CONNECTIVITY_DATA = 'SET_LOCATION_CONNECTIVITY_DATA';

export const FETCH_LOCATION_TICKETS_LIST_STATUS = 'FETCH_LOCATION_TICKETS_LIST_STATUS';
export const SET_LOCATION_TICKETS_LIST = 'SET_LOCATION_TICKETS_LIST';
export const FETCH_LOCATION_ORDERS_LIST_STATUS = 'FETCH_LOCATION_ORDERS_LIST_STATUS';
export const SET_LOCATION_ORDERS_LIST = 'SET_LOCATION_ORDERS_LIST';
export const SET_LOCATIONS_BULK = 'SET_LOCATIONS_BULK';
export const POST_LOCATIONS_BULK_DETAILS_STATUS = 'POST_LOCATIONS_BULK_DETAILS_STATUS';
export const POST_LOCATIONS_INFO_STATUS = 'POST_LOCATIONS_INFO_STATUS';
export const PATCH_LOCATIONS_INFO_STATUS = 'PATCH_LOCATIONS_INFO_STATUS';

// users CONSTANTS
export const SET_USERS_LIST = 'SET_USERS_LIST';
export const FETCH_USERS_LIST_STATUS = 'FETCH_USERS_LIST_STATUS';
export const SET_USERS_DETAILS = 'SET_USERS_DETAILS';
export const FETCH_USERS_DETAILS_STATUS = 'FETCH_USERS_DETAILS_STATUS';
export const DELETE_USERS_DETAILS_STATUS = 'DELETE_USERS_DETAILS_STATUS';
export const PATCH_CHANGE_PASSWORD_STATUS = 'PATCH_CHANGE_PASSWORD_STATUS';
export const SET_USERS_ROUTE_PARAMS = 'SET_USERS_ROUTE_PARAMS';
export const POST_USERS_DETAILS_STATUS = 'POST_USERS_DETAILS_STATUS';
export const PATCH_USERS_DETAILS_STATUS = 'PATCH_USERS_DETAILS_STATUS';
export const POST_AGREEMENT_STATUS = 'POST_AGREEMENT_STATUS';
export const DELETE_AGREEMENT_STATUS = 'DELETE_AGREEMENT_STATUS';
export const PATCH_AGREEMENT_STATUS = 'PATCH_AGREEMENT_STATUS';
export const SET_USER_ROLES_LIST = 'SET_USER_ROLES_LIST';
export const FETCH_USER_ROLES_LIST_STATUS = 'FETCH_USER_ROLES_LIST_STATUS';

// comments CONSTANTS
export const POST_COMMENTS_DETAILS_STATUS = 'POST_COMMENTS_DETAILS_STATUS';
export const PATCH_COMMENTS_DETAILS_STATUS = 'PATCH_COMMENTS_DETAILS_STATUS';

// services CONSTANTS
export const SET_SERVICES_LIST = 'SET_SERVICES_LIST';
export const FETCH_SERVICES_LIST_STATUS = 'FETCH_SERVICES_LIST_STATUS';
export const SET_SERVICES_DETAILS = 'SET_SERVICES_DETAILS';
export const FETCH_SERVICES_DETAILS_STATUS = 'FETCH_SERVICES_DETAILS_STATUS';
export const DELETE_SERVICES_DETAILS_STATUS = 'DELETE_SERVICES_DETAILS_STATUS';
export const SET_SERVICES_ROUTE_PARAMS = 'SET_SERVICES_ROUTE_PARAMS';
export const POST_SERVICES_DETAILS_STATUS = 'POST_SERVICES_DETAILS_STATUS';
export const PATCH_SERVICES_DETAILS_STATUS = 'PATCH_SERVICES_DETAILS_STATUS';
export const POST_CHECK_REVIO_SERVICE_STATUS = 'POST_CHECK_REVIO_SERVICE_STATUS';
export const POST_FIX_REVIO_SERVICE_STATUS = 'POST_FIX_REVIO_SERVICE_STATUS';
export const SET_CHECK_REVIO_SERVICE_DATA = 'SET_CHECK_REVIO_SERVICE_DATA';

// SMS LOGGER
export const SET_SMS_LOGGER_LIST = 'SET_SMS_LOGGER_LIST';
export const FETCH_SMS_LOGGER_LIST_STATUS = 'FETCH_SMS_LOGGER_LIST_STATUS';
export const SET_SMS_SIMS_LIST = 'SET_SMS_SIMS_LIST';
export const FETCH_SMS_SIMS_LIST_STATUS = 'FETCH_SMS_SIMS_LIST_STATUS';
export const SET_SMS_LOGGER_SUMMARY = 'SET_SMS_LOGGER_SUMMARY';
export const FETCH_SMS_LOGGER_SUMMARY_STATUS = 'FETCH_SMS_LOGGER_SUMMARY_STATUS';
export const SET_SMS_LOGGER_DEVICE_NAMES = 'SET_SMS_LOGGER_DEVICE_NAMES';
export const FETCH_SMS_LOGGER_DEVICE_NAMES_STATUS = 'FETCH_SMS_LOGGER_DEVICE_NAMES_STATUS';
export const SET_SMS_LOGGER_ACCESS_TOKEN = 'SET_SMS_LOGGER_ACCESS_TOKEN';
export const SET_SMS_SIM_DETAILS = 'SET_SMS_SIM_DETAILS';
export const FETCH_SMS_SIM_DETAILS_STATUS = 'FETCH_SMS_SIM_DETAILS_STATUS';
export const PATCH_SMS_SIM_DETAILS_STATUS = 'PATCH_SMS_SIM_DETAILS_STATUS';
export const POST_SMS_SIM_DETAILS_STATUS = 'POST_SMS_SIM_DETAILS_STATUS';
export const DELETE_SMS_SIM_DETAILS_STATUS = 'DELETE_SMS_SIM_DETAILS_STATUS';
export const SET_FULL_SMS_PLANS_LIST = 'SET_FULL_SMS_PLANS_LIST';
export const SET_FULL_SMS_PLANS_FETCH_STATUS = 'SET_FULL_SMS_PLANS_FETCH_STATUS';

// payment methods CONSTANTS
export const SET_PAYMENT_METHOD_LIST = 'SET_PAYMENT_METHOD_LIST';
export const FETCH_PAYMENT_METHOD_LIST_STATUS = 'FETCH_PAYMENT_METHOD_LIST_STATUS';
export const SET_ADD_FUND_FETCH_STATUS = 'SET_ADD_FUND_FETCH_STATUS';
export const POST_PAYMENT_METHOD_STATUS = 'POST_PAYMENT_METHOD_STATUS';
export const FETCH_SETUP_INTENT_STATUS = 'FETCH_SETUP_INTENT_STATUS';

// sims CONSTANTS
export const SET_SIMS_LIST = 'SET_SIMS_LIST';
export const FETCH_SIMS_LIST_STATUS = 'FETCH_SIMS_LIST_STATUS';
export const SET_SIMS_DETAILS = 'SET_SIMS_DETAILS';
export const FETCH_SIMS_DETAILS_STATUS = 'FETCH_SIMS_DETAILS_STATUS';
export const POST_SIMS_DETAILS_STATUS = 'POST_SIMS_DETAILS_STATUS';
export const DELETE_SIMS_DETAILS_STATUS = 'DELETE_SIMS_DETAILS_STATUS';
export const PATCH_SIMS_DETAILS_STATUS = 'PATCH_SIMS_DETAILS_STATUS';
export const SET_SIMS_BULK = 'SET_SIMS_BULK';
export const POST_SIMS_BULK_DETAILS_STATUS = 'POST_SIMS_BULK_DETAILS_STATUS';

// pbxs CONSTANTS
export const SET_PBXS_LIST = 'SET_PBXS_LIST';
export const FETCH_PBXS_LIST_STATUS = 'FETCH_PBXS_LIST_STATUS';
export const SET_PBXS_DETAILS = 'SET_PBXS_DETAILS';
export const FETCH_PBXS_DETAILS_STATUS = 'FETCH_PBXS_DETAILS_STATUS';
export const FETCH_PBXS_FIX_DIAL_RULE_STATUS = 'FETCH_PBXS_FIX_DIAL_RULE_STATUS';
export const DELETE_PBXS_DETAILS_STATUS = 'DELETE_PBXS_DETAILS_STATUS';
export const SET_PBXS_ROUTE_PARAMS = 'SET_PBXS_ROUTE_PARAMS';
export const POST_PBXS_DETAILS_STATUS = 'POST_PBXS_DETAILS_STATUS';
export const PATCH_PBXS_DETAILS_STATUS = 'PATCH_PBXS_DETAILS_STATUS';

// cdrs CONSTANTS
export const SET_DIALER_LOGS_LIST = 'SET_DIALER_LOGS_LIST';
export const FETCH_DIALER_LOGS_LIST_STATUS = 'FETCH_DIALER_LOGS_LIST_STATUS';
export const SET_DIALER_LOGS_ROUTE_PARAMS = 'SET_DIALER_LOGS_ROUTE_PARAMS';

// atas CONSTANTS
export const SET_ATAS_LIST = 'SET_ATAS_LIST';
export const FETCH_ATAS_LIST_STATUS = 'FETCH_ATAS_LIST_STATUS';
export const FETCH_ATA_CONFIG_FILE_STATUS = 'FETCH_ATA_CONFIG_FILE_STATUS';
export const SET_ATAS_DETAILS = 'SET_ATAS_DETAILS';
export const FETCH_ATAS_DETAILS_STATUS = 'FETCH_ATAS_DETAILS_STATUS';
export const SET_ATA_ACS_CONTACT_IDS = 'SET_ATA_ACS_CONTACT_IDS';
export const FETCH_ATA_ACS_CONTACT_IDS_STATUS = 'FETCH_ATA_ACS_CONTACT_IDS_STATUS';
export const SET_ATAS_ACS_DETAILS = 'SET_ATAS_ACS_DETAILS';
export const FETCH_ATAS_ACS_DETAILS_STATUS = 'FETCH_ATAS_ACS_DETAILS_STATUS';
export const DELETE_ATAS_DETAILS_STATUS = 'DELETE_ATAS_DETAILS_STATUS';
export const SET_ATAS_ROUTE_PARAMS = 'SET_ATAS_ROUTE_PARAMS';
export const POST_ATAS_DETAILS_STATUS = 'POST_ATAS_DETAILS_STATUS';
export const PATCH_ATAS_DETAILS_STATUS = 'PATCH_ATAS_DETAILS_STATUS';
export const POST_ATAS_ACS_DETAILS_STATUS = 'POST_ATAS_ACS_DETAILS_STATUS';
export const PATCH_ATAS_ACS_DETAILS_STATUS = 'PATCH_ATAS_ACS_DETAILS_STATUS';
export const SET_ATA_BULK = 'SET_ATA_BULK';
export const POST_ATAS_BULK_DETAILS_STATUS = 'POST_ATAS_BULK_DETAILS_STATUS';
export const FETCH_UPDATE_ATA_STATUS = 'FETCH_UPDATE_ATA_STATUS';
export const SET_ATAS_ACS_ADVANCED_SETTINGS = 'SET_ATAS_ACS_ADVANCED_SETTINGS';
export const SET_ATAS_ACS_ADVANCED_PORT_SETTINGS = 'SET_ATAS_ACS_ADVANCED_PORT_SETTINGS';
export const FETCH_ATAS_ACS_ADVANCED_SETTINGS_STATUS = 'FETCH_ATAS_ACS_ADVANCED_SETTINGS_STATUS';
export const FETCH_ATAS_ACS_ADVANCED_PORT_SETTINGS_STATUS = 'FETCH_ATAS_ACS_ADVANCED_PORT_SETTINGS_STATUS';
export const POST_ATAS_ACS_ADVANCED_SETTINGS_DETAILS_STATUS = 'POST_ATAS_ACS_ADVANCED_SETTINGS_DETAILS_STATUS';
export const POST_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS =
  'POST_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS';
export const RESET_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS =
  'POST_RESET_ATAS_ACS_ADVANCED_PORT_SETTINGS_DETAILS_STATUS';
export const RESET_ATAS_ACS_ADVANCED_SETTINGS_DETAILS_STATUS = 'POST_RESET_ATAS_ACS_ADVANCED_SETTINGS_DETAILS_STATUS';
export const FETCH_UPDATE_ALL_GS_ATAS_CONFINGS_STATUS = 'FETCH_UPDATE_ALL_GS_ATAS_CONFINGS_STATUS';

// ata org templates CONSTANTS
export const SET_ATA_ORG_TEMPLATES_LIST = 'SET_ATA_ORG_TEMPLATES_LIST';
export const FETCH_ATA_ORG_TEMPLATES_LIST_STATUS = 'FETCH_ATA_ORG_TEMPLATES_LIST_STATUS';
export const SET_ATA_ORG_TEMPLATES_DETAILS = 'SET_ATA_ORG_TEMPLATES_DETAILS';
export const FETCH_ATA_ORG_TEMPLATES_DETAILS_STATUS = 'FETCH_ATA_ORG_TEMPLATES_DETAILS_STATUS';
export const DELETE_ATA_ORG_TEMPLATES_DETAILS_STATUS = 'DELETE_ATA_ORG_TEMPLATES_DETAILS_STATUS';
export const POST_ATA_ORG_TEMPLATES_DETAILS_STATUS = 'POST_ATA_ORG_TEMPLATES_DETAILS_STATUS';
export const PATCH_ATA_ORG_TEMPLATES_DETAILS_STATUS = 'PATCH_ATA_ORG_TEMPLATES_DETAILS_STATUS';
export const PATCH_ATA_ORG_TEMPLATE_OVERRIDES_STATUS = 'PATCH_ATA_ORG_TEMPLATE_OVERRIDES_STATUS';
export const POST_ATA_ORG_TEMPLATES_BULK_STATUS = 'POST_ATA_ORG_TEMPLATES_BULK_STATUS';

// ata base templates CONSTANTS
export const SET_ATA_BASE_TEMPLATES_LIST = 'SET_ATA_BASE_TEMPLATES_LIST';
export const FETCH_ATA_BASE_TEMPLATES_LIST_STATUS = 'FETCH_ATA_BASE_TEMPLATES_LIST_STATUS';
export const SET_ATA_BASE_TEMPLATES_DETAILS = 'SET_ATA_BASE_TEMPLATES_DETAILS';
export const FETCH_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'FETCH_ATA_BASE_TEMPLATES_DETAILS_STATUS';
export const DELETE_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'DELETE_ATA_BASE_TEMPLATES_DETAILS_STATUS';
export const SET_ATA_BASE_TEMPLATES_ROUTE_PARAMS = 'SET_ATA_BASE_TEMPLATES_ROUTE_PARAMS';
export const POST_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'POST_ATA_BASE_TEMPLATES_DETAILS_STATUS';
export const PATCH_ATA_BASE_TEMPLATES_DETAILS_STATUS = 'PATCH_ATA_BASE_TEMPLATES_DETAILS_STATUS';
export const PATCH_GS_BASE_CONFIG_DETAILS_STATUS = 'PATCH_GS_BASE_CONFIG_DETAILS_STATUS';

// ata types CONSTANTS
export const SET_ATA_TYPES_LIST = 'SET_ATA_TYPES_LIST';
export const FETCH_ATA_TYPES_LIST_STATUS = 'FETCH_ATA_TYPES_LIST_STATUS';
export const SET_ATA_TYPES_DETAILS = 'SET_ATA_TYPES_DETAILS';
export const FETCH_ATA_TYPES_DETAILS_STATUS = 'FETCH_ATA_TYPES_DETAILS_STATUS';
export const DELETE_ATA_TYPES_DETAILS_STATUS = 'DELETE_ATA_TYPES_DETAILS_STATUS';
export const POST_ATA_TYPES_DETAILS_STATUS = 'POST_ATA_TYPES_DETAILS_STATUS';
export const PATCH_ATA_TYPES_DETAILS_STATUS = 'PATCH_ATA_TYPES_DETAILS_STATUS';
export const SET_ATA_TYPES_ROUTE_PARAMS = 'SET_ATA_TYPES_ROUTE_PARAMS';

// data plan types CONSTANTS
export const SET_DATA_PLAN_TYPES_LIST = 'SET_DATA_PLAN_TYPES_LIST';
export const FETCH_DATA_PLAN_TYPES_LIST_STATUS = 'FETCH_DATA_PLAN_TYPES_LIST_STATUS';
export const SET_DATA_PLAN_TYPES_DETAILS = 'SET_DATA_PLAN_TYPES_DETAILS';
export const FETCH_DATA_PLAN_TYPES_DETAILS_STATUS = 'FETCH_DATA_PLAN_TYPES_DETAILS_STATUS';
export const DELETE_DATA_PLAN_TYPES_DETAILS_STATUS = 'DELETE_DATA_PLAN_TYPES_DETAILS_STATUS';
export const SET_DATA_PLAN_TYPES_ROUTE_PARAMS = 'SET_DATA_PLAN_TYPES_ROUTE_PARAMS';
export const POST_DATA_PLAN_TYPES_DETAILS_STATUS = 'POST_DATA_PLAN_TYPES_DETAILS_STATUS';
export const PATCH_DATA_PLAN_TYPES_DETAILS_STATUS = 'PATCH_DATA_PLAN_TYPES_DETAILS_STATUS';

// data plans CONSTANTS
export const SET_DATA_PLAN_LIST = 'SET_DATA_PLAN_LIST';
export const FETCH_DATA_PLAN_LIST_STATUS = 'FETCH_DATA_PLAN_LIST_STATUS';
export const SET_DATA_PLAN_DETAILS = 'SET_DATA_PLAN_DETAILS';
export const FETCH_DATA_PLAN_DETAILS_STATUS = 'FETCH_DATA_PLAN_DETAILS_STATUS';
export const DELETE_DATA_PLAN_DETAILS_STATUS = 'DELETE_DATA_PLAN_DETAILS_STATUS';
export const SET_DATA_PLAN_ROUTE_PARAMS = 'SET_DATA_PLAN_ROUTE_PARAMS';
export const POST_DATA_PLAN_DETAILS_STATUS = 'POST_DATA_PLAN_DETAILS_STATUS';
export const PATCH_DATA_PLAN_DETAILS_STATUS = 'PATCH_DATA_PLAN_DETAILS_STATUS';

// data plans CONSTANTS
export const POST_COUNTRIES_DETAILS_STATUS = 'POST_COUNTRIES_DETAILS_STATUS';
export const POST_ENABLE_COUNTRY_STATUS = 'POST_ENABLE_COUNTRY_STATUS';
export const POST_DISABLE_COUNTRY_STATUS = 'POST_DISABLE_COUNTRY_STATUS';
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
export const FETCH_COUNTRIES_LIST_STATUS = 'FETCH_COUNTRIES_LIST_STATUS';
export const SET_COUNTRIES_DETAILS = 'SET_COUNTRIES_DETAILS';
export const FETCH_COUNTRIES_DETAILS_STATUS = 'FETCH_COUNTRIES_DETAILS_STATUS';
export const PATCH_COUNTRIES_DETAILS_STATUS = 'PATCH_COUNTRIES_DETAILS_STATUS';

// service types CONSTANTS
export const SET_SERVICE_TYPES_LIST = 'SET_SERVICE_TYPES_LIST';
export const FETCH_SERVICE_TYPES_LIST_STATUS = 'FETCH_SERVICE_TYPES_LIST_STATUS';
export const SET_SERVICE_TYPES_DETAILS = 'SET_SERVICE_TYPES_DETAILS';
export const FETCH_SERVICE_TYPES_DETAILS_STATUS = 'FETCH_SERVICE_TYPES_DETAILS_STATUS';
export const DELETE_SERVICE_TYPES_DETAILS_STATUS = 'DELETE_SERVICE_TYPES_DETAILS_STATUS';
export const POST_SERVICE_TYPES_DETAILS_STATUS = 'POST_SERVICE_TYPES_DETAILS_STATUS';
export const PATCH_SERVICE_TYPES_DETAILS_STATUS = 'PATCH_SERVICE_TYPES_DETAILS_STATUS';
export const SET_SERVICE_TYPES_ROUTE_PARAMS = 'SET_SERVICE_TYPES_ROUTE_PARAMS';

export const UPLOAD_SERVICE_TYPES_OVERRIDE_STATUS = 'UPLOAD_SERVICE_TYPES_OVERRIDE_STATUS';
export const SET_SERVICE_TYPES_OVERRIDE = 'SET_SERVICE_TYPES_OVERRIDE';
export const FETCH_SERVICE_TYPES_OVERRIDE_STATUS = 'FETCH_SERVICE_TYPES_OVERRIDE_STATUS';

// stats CONSTANTS
export const SET_STATS_DATA = 'SET_STATS_DATA';
export const FETCH_STATS_DATA_STATUS = 'FETCH_STATS_DATA_STATUS';

// files CONSTANTS
export const UPLOAD_FILE_DATA_STATUS = 'UPLOAD_FILE_DATA_STATUS';
export const PATCH_FILE_DATA_STATUS = 'PATCH_FILE_DATA_STATUS';
export const DELETE_FILE_DATA_STATUS = 'DELETE_FILE_DATA_STATUS';

// notes CONSTANTS
export const DELETE_NOTES_DETAILS_STATUS = 'DELETE_NOTES_DETAILS_STATUS';
export const POST_NOTES_DETAILS_STATUS = 'POST_NOTES_DETAILS_STATUS';
export const PATCH_NOTES_DETAILS_STATUS = 'PATCH_NOTES_DETAILS_STATUS';

// other CONSTANTS
export const FETCH_FACTORY_RESET_ATA_STATUS = 'FETCH_FACTORY_RESET_ATA_STATUS';
export const FETCH_REBOOT_ATA_STATUS = 'FETCH_REBOOT_ATA_STATUS';

// SETTINGS CONSTANTS
export const LOGO_BG = 'LOGO_BG';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const THEME = 'THEME';
export const DIRECTION = 'DIRECTION';
export const SIDEBAR_POSITION = 'SIDEBAR_POSITION';
export const HEADER_POSITION = 'HEADER_POSITION';
export const LAYOUT = 'LAYOUT';
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE';
export const SET_SIDEBAR_IS_OPEN = 'SET_SIDEBAR_IS_OPEN';
export const SET_SHOW_SELECT_BOXES = 'SET_SHOW_SELECT_BOXES';

// Sync routes CONSTANTS
export const SET_SYNC_ROUTERS_LIST = 'SET_SYNC_ROUTERS_LIST';
export const FETCH_SYNC_ROUTERS_LIST_STATUS = 'FETCH_SYNC_ROUTERS_LIST_STATUS';
export const SET_NOT_SYNC_ROUTERS_LIST = 'SET_NOT_SYNC_ROUTERS_LIST';
export const FETCH_NOT_SYNC_ROUTERS_LIST_STATUS = 'FETCH_NOT_SYNC_ROUTERS_LIST_STATUS';
export const PATCH_SYNC_ROUTERS_LIST_STATUS = 'PATCH_SYNC_ROUTERS_LIST_STATUS';
export const DELETE_SYNC_ROUTERS_LIST_STATUS = 'DELETE_SYNC_ROUTERS_LIST_STATUS';
export const PATCH_SYNC_ROUTERS_DETAILS_STATUS = 'PATCH_SYNC_ROUTERS_DETAILS_STATUS';
export const FETCH_AVAILABLE_ROUTERS_LIST_STATUS = 'FETCH_AVAILABLE_ROUTERS_LIST_STATUS';

// API CREDENTIALS CONSTANTS
export const SET_API_CREDENTIALS = 'SET_API_CREDENTIALS';
export const FETCH_API_CREDENTIALS_STATUS = 'FETCH_API_CREDENTIALS_STATUS';
export const POST_API_CREDENTIALS_STATUS = 'POST_API_CREDENTIALS_STATUS';
export const PATCH_API_CREDENTIALS_STATUS = 'PATCH_API_CREDENTIALS_STATUS';
export const DELETE_API_CREDENTIALS_STATUS = 'DELETE_API_CREDENTIALS_STATUS';

// async xmlRequestLogs CONSTANTS
export const SET_XML_REQUEST_LOGS_LIST = 'SET_XML_REQUEST_LOGS_LIST';
export const FETCH_XML_REQUEST_LOGS_LIST_STATUS = 'FETCH_XML_REQUEST_LOGS_LIST_STATUS';

// async actionLogs CONSTANTS
export const SET_TASK_ATA_LOGS_LIST = 'SET_TASK_ATA_LOGS_LIST';
export const FETCH_TASK_ATA_LOGS_LIST_STATUS = 'FETCH_TASK_ATA_LOGS_LIST_STATUS';

// async GDSM Callback Logs CONSTANTS
export const SET_GDMS_CALLBACK_LOGS_LIST = 'SET_GDMS_CALLBACK_LOGS_LIST';
export const FETCH_GDMS_CALLBACK_LOGS_LIST_STATUS = 'FETCH_GDMS_CALLBACK_LOGS_LIST_STATUS';

// tickets CONSTANTS
export const SET_TICKETS_LIST = 'SET_TICKETS_LIST';
export const FETCH_TICKETS_LIST_STATUS = 'FETCH_TICKETS_LIST_STATUS';
export const DELETE_TICKETS_DETAILS_STATUS = 'DELETE_TICKETS_DETAILS_STATUS';
export const SET_TICKETS_DETAILS = 'SET_TICKETS_DETAILS';
export const FETCH_TICKETS_DETAILS_STATUS = 'FETCH_TICKETS_DETAILS_STATUS';
export const POST_TICKETS_DETAILS_STATUS = 'POST_TICKETS_DETAILS_STATUS';
export const PATCH_TICKET_DETAILS_STATUS = 'PATCH_TICKET_DETAILS_STATUS';
export const SET_TICKET_USERS_LIST = 'SET_TICKET_USERS_LIST';
export const FETCH_TICKET_USERS_LIST_STATUS = 'FETCH_TICKET_USERS_LIST_STATUS';

// ENCLOSURE TYPES
export const SET_ENCLOSURE_TYPES_LIST = 'SET_ENCLOSURE_TYPES_LIST';
export const FETCH_ENCLOSURE_TYPES_LIST_STATUS = 'FETCH_ENCLOSURE_TYPES_LIST_STATUS';
export const DELETE_ENCLOSURE_TYPES_DETAILS_STATUS = 'DELETE_ENCLOSURE_TYPES_DETAILS_STATUS';
export const POST_ENCLOSURE_TYPES_DETAILS_STATUS = 'POST_ENCLOSURE_TYPES_DETAILS_STATUS';
export const PATCH_ENCLOSURE_TYPES_DETAILS_STATUS = 'PATCH_ENCLOSURE_TYPES_DETAILS_STATUS';
export const SET_ENCLOSURE_TYPES_DETAILS = 'SET_ENCLOSURE_TYPES_DETAILS';
export const FETCH_ENCLOSURE_TYPES_DETAILS_STATUS = 'FETCH_ENCLOSURE_TYPES_DETAILS_STATUS';

// ENCLOSURE
export const SET_ENCLOSURES_LIST = 'SET_ENCLOSURES_LIST';
export const FETCH_ENCLOSURES_LIST_STATUS = 'FETCH_ENCLOSURES_LIST_STATUS';
export const DELETE_ENCLOSURES_DETAILS_STATUS = 'DELETE_ENCLOSURES_DETAILS_STATUS';
export const POST_ENCLOSURES_DETAILS_STATUS = 'POST_ENCLOSURES_DETAILS_STATUS';
export const PATCH_ENCLOSURES_DETAILS_STATUS = 'PATCH_ENCLOSURES_DETAILS_STATUS';
export const SET_ENCLOSURES_DETAILS = 'SET_ENCLOSURES_DETAILS';
export const FETCH_ENCLOSURES_DETAILS_STATUS = 'FETCH_ENCLOSURES_DETAILS_STATUS';
export const SET_ENCLOSURES_BULK = 'SET_ENCLOSURES_BULK';
export const POST_ENCLOSURES_BULK_DETAILS_STATUS = 'POST_ENCLOSURES_BULK_DETAILS_STATUS';

// attachments
export const DELETE_ATTACHMENTS_STATUS = 'DELETE_ATTACHMENTS_STATUS';
export const POST_ATTACHMENT_STATUS = 'POST_ATTACHMENT_STATUS';
export const SET_POST_ATTACHMENT_DETAILS = 'SET_POST_ATTACHMENT_DETAILS';

// Inventories
export const SET_INVENTORIES_LIST = 'SET_INVENTORIES_LIST';
export const FETCH_INVENTORIES_LIST_STATUS = 'FETCH_INVENTORIES_LIST_STATUS';
export const SET_INVENTORY_STATS = 'SET_INVENTORY_STATS';
export const FETCH_INVENTORY_STATS_STATUS = 'FETCH_INVENTORY_STATS_STATUS';
export const POST_INVENTORY_MOVE_STATUS = 'POST_INVENTORY_MOVE_STATUS';

//  VERIFICATION
export const POST_VERIFIED_NUMBER_DETAILS_STATUS = 'POST_VERIFIED_NUMBER_DETAILS_STATUS';
export const POST_VERIFIED_EMAIL_DETAILS_STATUS = 'POST_VERIFIED_EMAIL_DETAILS_STATUS';
export const DELETE_VERIFIED_NUMBER_DETAILS_STATUS = 'DELETE_VERIFIED_NUMBER_DETAILS_STATUS';
export const DELETE_VERIFIED_EMAIL_DETAILS_STATUS = 'DELETE_VERIFIED_EMAIL_DETAILS_STATUS';
export const SET_VERIFIED_NUMBER_DETAILS = 'SET_VERIFIED_NUMBER_DETAILS';
export const VERIFY_VERIFIED_NUMBER_DETAILS_STATUS = 'VERIFY_VERIFIED_NUMBER_DETAILS_STATUS';
export const FETCH_RESEND_VERIFIED_EMAIL_DETAILS_STATUS = 'FETCH_RESEND_VERIFIED_EMAIL_DETAILS_STATUS';
export const FETCH_RESEND_VERIFIED_NUMBER_DETAILS_STATUS = 'FETCH_RESEND_VERIFIED_NUMBER_DETAILS_STATUS';

// USER ACCESS
export const SET_USER_ACCESS_LIST = 'SET_USER_ACCESS_LIST';
export const FETCH_USER_ACCESS_LIST_STATUS = 'FETCH_USER_ACCESS_LIST_STATUS';
export const POST_SWITCH_USER_ORGANIZATION_STATUS = 'POST_SWITCH_USER_ORGANIZATION_STATUS';
export const POST_USER_ACCESS_STATUS = 'POST_USER_ACCESS_STATUS';
export const DELETE_USER_ACCESS_STATUS = 'DELETE_USER_ACCESS_STATUS';

// IP
export const FETCH_USER_IP_STATUS = 'FETCH_USER_IP_STATUS';
export const SET_FULL_PROFILE_LIST = 'SET_FULL_PROFILE_LIST';
