import {
  SET_SYNC_ROUTERS_LIST,
  SET_ROUTE_PARAMS,
  FETCH_SYNC_ROUTERS_LIST_STATUS,
  PATCH_SYNC_ROUTERS_LIST_STATUS,
  SET_NOT_SYNC_ROUTERS_LIST,
  FETCH_NOT_SYNC_ROUTERS_LIST_STATUS,
  FETCH_AVAILABLE_ROUTERS_LIST_STATUS,
  DELETE_SYNC_ROUTERS_LIST_STATUS,
  PATCH_SYNC_ROUTERS_DETAILS_STATUS,
} from '../constants';

export const initialRouteParams = {
  sync: {
    page: 1,
    limit: 10,
    is_active: 1,
    q: '',
    order: '',
  },
  notSync: {
    page: 1,
    limit: 10,
    is_active: 1,
    q: '',
    order: '',
  },
};

const INIT_STATE = {
  syncRouterData: [],
  syncRoutersListFetchStatus: null,
  notSyncRouterData: [],
  notSyncRoutersListFetchStatus: null,
  syncRoutersListPatchStatus: null,
  syncRoutersListDeleteStatus: null,
  syncRoutersDetailsPatchStatus: null,
  updateAvailableRoutersFetchStatus: null,
  routeParams: initialRouteParams,
};

export const syncRoutersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SYNC_ROUTERS_LIST:
      return { ...state, syncRouterData: action.payload };
    case FETCH_SYNC_ROUTERS_LIST_STATUS:
      return { ...state, syncRoutersListFetchStatus: action.payload };
    case SET_NOT_SYNC_ROUTERS_LIST:
      return { ...state, notSyncRouterData: action.payload };
    case FETCH_NOT_SYNC_ROUTERS_LIST_STATUS:
      return { ...state, notSyncRoutersListFetchStatus: action.payload };
    case PATCH_SYNC_ROUTERS_LIST_STATUS:
      return { ...state, syncRoutersListPatchStatus: action.payload };
    case FETCH_AVAILABLE_ROUTERS_LIST_STATUS:
      return { ...state, updateAvailableRoutersFetchStatus: action.payload };
    case DELETE_SYNC_ROUTERS_LIST_STATUS:
      return { ...state, syncRoutersListDeleteStatus: action.payload };
    case PATCH_SYNC_ROUTERS_DETAILS_STATUS:
      return { ...state, syncRoutersDetailsPatchStatus: action.payload };
    case SET_ROUTE_PARAMS:
      if (action.payload.reducer === 'syncRouters') {
        return {
          ...state,
          routeParams: { ...state.routeParams, sync: { ...state.routeParams.sync, ...action.payload.data } },
        };
      }
      if (action.payload.reducer === 'notSyncRouters') {
        return {
          ...state,
          routeParams: { ...state.routeParams, notSync: { ...state.routeParams.notSync, ...action.payload.data } },
        };
      }
      return { ...state };
    default:
      return state;
  }
};
